import React, { useEffect, useState } from "react";
import LayoutDefault from '../layout/Layout';
import "animate.css"
import Dirt from '../../assets/images/Dirt.jpg';
import BOMIssue from '../../assets/images/caution-sign.svg';
import BackorderParts from '../../assets/images/maintenance-1.svg';
import DesignRequest from '../../assets/images/design.svg';
import ProductionOrder from '../../assets/images/box.svg';
import Transfer from '../../assets/images/transfer.png';
import IT from '../../assets/images/customer-service.svg';


document.title ="The K.A. Group Internal Support"; 

// SANITIZE HTML DATA
// var sanitizer = DOMPurify.sanitize;

const Design = ({
  
}) => {

    const [isActive, setActive] = useState(false);


    const toggleClass = () => {
        setActive(!isActive);
    };

    const SmartText = ({ text, length = 130 }) => {
        const [showLess, setShowLess] = React.useState(true);
      
        if (text.length < length) {
          return <p>{text}</p>;
        }
      
        return (
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: showLess ? `${text.slice(0, length)}...` : text,
              }}
            ></p>
            <a
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => setShowLess(!showLess)}
            >
              &nbsp;View {showLess ? "More" : "Less"}
            </a>
          </div>
        );
    };

    return (
        <>
            <LayoutDefault className="width-1200">
                <img  className='dirt-img' src={Dirt} alt="Dirt" />

                <a style={{ fontSize: '20px', margin: '30px 0', display: 'block' }} href="/">&lt; Back</a>

                <h1 style={{ marginBottom: '23px' }} className="animate__animated animate__fadeInLeft animate__fast">Design and Production Processing</h1>
                <p style={{ marginBottom: '78.5px' }} className="animate__animated animate__fadeInLeft animate__fast">Submit any production processing issues, design ideas, jig requests, or production order requests.</p>
                
                <div className="block-row row">
                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={BOMIssue} alt="BOM Issue" />
                      <h2>BOM Issue</h2>
                      <SmartText text="Alert the production processing team for any issues across work orders that are released, design software, and ERP." />
                      <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-17087/IN4ACLKD6YDT41THL7?Job%20Type=BOM%20Issues&Destination%20List=Production%20Processing" target="_blank" rel="noopener noreferrer">Submit Request</a>
                  </div>

                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={DesignRequest} alt="Design Change" />
                      <h2>Design Change</h2>
                      <SmartText text="Submit a design idea and we'll determine if we should build it into our product based on the data we receive." />
                      <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-17127/ZOBCBOBJ1L6IK7VA43?Destination%20List=Product%20Support&Product%20Support%20Category=Design%20Request" target="_blank" rel="noopener noreferrer">Submit Request</a>
                  </div>

                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={IT} alt="Design Support" />
                      <h2>Design Support for Production</h2>
                      <SmartText text="Ticket to get help from Barry on the floor or get a Drawing/DXF adjusted." />
                      <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-17127/ZOBCBOBJ1L6IK7VA43?Destination%20List=Product%20Support&Product%20Support%20Category=Design%20Support%20for%20Production" target="_blank" rel="noopener noreferrer">Submit Request</a>
                  </div>

                </div>

                <div className="block-row row">
                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={BackorderParts} alt="Jig Request" />
                      <h2>Jig Request</h2>
                      <SmartText text="Request a jig to help with repetitive tasks and maximize work efficiency." />
                      <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-17147/YN480EUGAPXCPMWZ5Q?Destination%20List=Jigs" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                  </div>

                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={Transfer} alt="Work Order Closing" />
                      <h2>Work Order Closing</h2>
                      <SmartText text="Ticket for submitting order closing requests, either for jobs with shortages or complete finished goods for the planning team to process." />
                      <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-28051/R66DSETU7EEZI7J0YG?Destination%20List=OrderClosing&Job%20Type=Order%20Closing" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                  </div>

                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={ProductionOrder} alt="Production Order" />
                      <h2>Production Order</h2>
                      <SmartText text="For special requests regarding R&D, service & parts sales, and everything in between." />
                      <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-17187/YETP408ACFNCHPMV2R?Job%20Type=Processing&Destination%20List=Production%20Processing" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                  </div>
                </div>
            </LayoutDefault>
        </>
    );
}

export default Design;