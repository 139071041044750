import React from 'react';
import classNames from 'classnames';
import Logo from '../../../assets/images/avatar.svg';

const Header = ({
  className,
  ID,
  ...props
}) => {

  const classes = classNames(
    'header',
    className
  );

  return (
    <>
      <a className="skip-link screen-reader-text" href="#content">Skip to Content</a>
      <header
        {...props}
        className={classes}
        id={ID}
      >
        <div className='animate__animated animate__fadeIn animate__slow width-1500'>
          <span><img src={Logo} alt="The K.A. Group Internal Support" /></span>
          <span>The K.A. Group Internal Support</span>        
        </div>
      </header>
    </>
  );
}


export default Header;