import React, { useEffect, useState } from "react";
import LayoutDefault from '../layout/Layout';
import "animate.css"
import PaperAndPencil from '../../assets/images/piece-of-paper-and-pencil.png';
import Cog from '../../assets/images/clutch-disc.png';
import Dirt from '../../assets/images/Dirt.jpg';
import Cost from '../../assets/images/CostTracking.png';


document.title ="The K.A. Group Internal Support"; 

// SANITIZE HTML DATA
// var sanitizer = DOMPurify.sanitize;

const QCCostTracking = ({
  
}) => {

    const [isActive, setActive] = useState(false);


    const toggleClass = () => {
        setActive(!isActive);
    };

    const SmartText = ({ text, length = 130 }) => {
        const [showLess, setShowLess] = React.useState(true);
      
        if (text.length < length) {
          return <p>{text}</p>;
        }
      
        return (
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: showLess ? `${text.slice(0, length)}...` : text,
              }}
            ></p>
            <a
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => setShowLess(!showLess)}
            >
              &nbsp;View {showLess ? "More" : "Less"}
            </a>
          </div>
        );
    };

    return (
        <>
            <LayoutDefault className="width-1200">
                <img  className='dirt-img' src={Dirt} alt="Dirt" />

                <a style={{ fontSize: '20px', margin: '30px 0', display: 'block' }} href="/quality">&lt; Back</a>

                <h1 style={{ marginBottom: '23px' }} className="animate__animated animate__fadeInLeft animate__fast">Quality Control Cost Tracking</h1>
                <p style={{ marginBottom: '78.5px' }} className="animate__animated animate__fadeInLeft animate__fast">Please use the forms below so that we can keep track of costs on our Quality issues.</p>
                
                <div className="block-row row">
                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={PaperAndPencil} alt="Paper and Pencil" />
                        <h2>PreFab Quality Issues</h2>
                        <SmartText text="Help track recurring issues and stop one-offs by submitting all quality issues. Big or small, it must be logged." />
                        <a className="custom-btn" href="https://forms.office.com/Pages/ResponsePage.aspx?id=JxF44ckTQUK_Ecq91KnG_Y0bebsIpcdNrAzNiv1Zt2FURDdROElLMTQ5TVhJS0VHREw1TEJCMjROVyQlQCN0PWcu" target="_blank" rel="noopener noreferrer">Submit Request</a>
                    </div>

                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                    <img src={PaperAndPencil} alt="Paper and Pencil" />
                        <h2>Machine Shop Quality Issues</h2>
                        <SmartText text="Submit a ticket to start the investigation process and help prevent future delivery issues to our customers." />
                        <a className="custom-btn" href="https://forms.office.com/Pages/ResponsePage.aspx?id=JxF44ckTQUK_Ecq91KnG_Y0bebsIpcdNrAzNiv1Zt2FURjQ2NzRGQ1BIUVI1TVZQVVkyOFZaV1BXUyQlQCN0PWcu" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                    </div>

                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                    <img src={PaperAndPencil} alt="Paper and Pencil" />
                        <h2>Weld Quality Issues</h2>
                        <SmartText text="Forms for tracking Quality Issue Costs." />
                        <a className="custom-btn" href="https://forms.office.com/Pages/ResponsePage.aspx?id=JxF44ckTQUK_Ecq91KnG_Y0bebsIpcdNrAzNiv1Zt2FUMURYRThSTlJKSkFURDhDRVhTNUI3MklaQyQlQCN0PWcu" target="_blank" rel="noopener noreferrer">View More</a>                        
                    </div>
                </div>

                <div className="block-row row">
                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={PaperAndPencil} alt="Paper and Pencil" />
                        <h2>Paint Quality Issues</h2>
                        <SmartText text="Help track recurring issues and stop one-offs by submitting all quality issues. Big or small, it must be logged." />
                        <a className="custom-btn" href="https://forms.office.com/Pages/ResponsePage.aspx?id=JxF44ckTQUK_Ecq91KnG_Y0bebsIpcdNrAzNiv1Zt2FUNUtDMVJWNkpZNlJXUEZSSkFXV0NHRjNUOSQlQCN0PWcu" target="_blank" rel="noopener noreferrer">Submit Request</a>
                    </div>

                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                    <img src={PaperAndPencil} alt="Paper and Pencil" />
                        <h2>Assembly Quality Issues</h2>
                        <SmartText text="Submit a ticket to start the investigation process and help prevent future delivery issues to our customers." />
                        <a className="custom-btn" href="https://forms.office.com/Pages/ResponsePage.aspx?id=JxF44ckTQUK_Ecq91KnG_Y0bebsIpcdNrAzNiv1Zt2FUN09XRzBLTThaOE5OOFBQTUpSR0NJWFRISiQlQCN0PWcu" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                    </div>

                    <div className="column"></div>


                </div>
            </LayoutDefault>
        </>
    );
}

export default QCCostTracking;