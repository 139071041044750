import React, { useEffect, useState } from "react";
import LayoutDefault from '../layout/Layout';
import "animate.css"
import Dirt from '../../assets/images/Dirt.jpg';
import NewEmployee from '../../assets/images/new-employee.png';
import NewUser from '../../assets/images/add-user-(1).svg';
import Ticket from '../../assets/images/piece-of-paper-and-pencil.png';


document.title ="The K.A. Group Internal Support"; 

// SANITIZE HTML DATA
// var sanitizer = DOMPurify.sanitize;

const InformationTechnology = ({
  
}) => {

    const [isActive, setActive] = useState(false);


    const toggleClass = () => {
        setActive(!isActive);
    };

    const SmartText = ({ text, length = 150 }) => {
        const [showLess, setShowLess] = React.useState(true);
      
        if (text.length < length) {
          return <p>{text}</p>;
        }
      
        return (
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: showLess ? `${text.slice(0, length)}...` : text,
              }}
            ></p>
            <a
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => setShowLess(!showLess)}
            >
              &nbsp;View {showLess ? "More" : "Less"}
            </a>
          </div>
        );
    };

    return (
        <>
            <LayoutDefault className="width-1200">
                <img  className='dirt-img' src={Dirt} alt="Dirt" />

                <a style={{ fontSize: '20px', margin: '30px 0', display: 'block' }} href="/">&lt; Back</a>

                <h1 style={{ marginBottom: '23px' }} className="animate__animated animate__fadeInLeft animate__fast">Information Technology</h1>
                <p style={{ marginBottom: '78.5px' }} className="animate__animated animate__fadeInLeft animate__fast">Tech setup requests for new employees and ClickUp user creation.</p>
                
                <div className="block-row row">
                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={NewEmployee} alt="New Employee" />
                      <h2>New Employee/Position Change</h2>
                      <SmartText text="Submit employee information for new hires or any changes to current employees." />
                      <a style={{ marginTop: '54px' }} className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-23082/T1V5GBV4K41IMCL0PF?Destination%20List=NewEmployee" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                  </div>

                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={NewUser} alt="New ClickUp User" />
                      <h2>New ClickUp User</h2>
                      <SmartText text="Request creations for new ClickUp users and we'll provide the training. For mulitple users include all email addresses in a single submission." />
                      <a className="custom-btn" href="https://forms.clickup.com/f/dhthx-18167/WDZANMUS9ZYHYNIDRH?Destination%20List=New%20ClickUp%20User" target="_blank" rel="noopener noreferrer">Submit Request</a>
                  </div>

                  <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                      <img src={Ticket} alt="Reporting Ticket" />
                      <h2>New Report Request</h2>
                      <SmartText text="Enter a report into the funnel and the reporting team will assess and determine the best course of action." />
                      <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-31211/O93T05N28A0D5K528O?Destination%20List=IT&IT%20Category=Reporting" target="_blank" rel="noopener noreferrer">Submit Request</a>
                  </div>

                </div>
            </LayoutDefault>
        </>
    );
}

export default InformationTechnology;