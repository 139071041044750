import React from 'react';
import './assets/css/App.css';
import { BrowserRouter, Route, Routes, useNavigate, redirect} from 'react-router-dom';
import Home from './components/views/Home';
import Quality from "./components/views/Quality";
import Logistics from "./components/views/Logistics";
import Header from './components/layout/partials/Header';
import Footer from './components/layout/partials/Footer';
import ScrollToTop from './components/elements/ScrollToTop';
import InformationTechnology from './components/views/InformationTechnology';
import Design from './components/views/Design';
import Inventory from './components/views/Inventory';
import QCCostTracking from './components/views/QCCostTracking';
import HumanResources from './components/views/HumanResources';


function App() {
  return (
      <BrowserRouter>
        <Header />
        <ScrollToTop />

        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/quality' element={<Quality />}></Route>
          <Route path='/logistics' element={<Logistics />}></Route>
          <Route path='/Inventory' element={<Inventory />}></Route>
          <Route path='/information-technology' element={<InformationTechnology />}></Route>
          <Route path='/human-resources' element={<HumanResources />}></Route>
          <Route path='/design-production-processing' element={<Design />}></Route>
          <Route path='/QCCostTracking' element={<QCCostTracking />}></Route>
        </Routes>

        <Footer />
      </BrowserRouter>
  );
}

export default App;