import React, { useEffect, useState } from "react";
import LayoutDefault from '../layout/Layout';
import "animate.css"
import PaperAndPencil from '../../assets/images/piece-of-paper-and-pencil.png';
import Cog from '../../assets/images/clutch-disc.png';
import Dirt from '../../assets/images/Dirt.jpg';
import Box from '../../assets/images/box.png';
import Globe from '../../assets/images/globe.png';
import Transfer from '../../assets/images/transfer.png';


document.title ="The K.A. Group Internal Support"; 

// SANITIZE HTML DATA
// var sanitizer = DOMPurify.sanitize;

const Logistics = ({
  
}) => {

    const [isActive, setActive] = useState(false);


    const toggleClass = () => {
        setActive(!isActive);
    };

    const SmartText = ({ text, length = 130 }) => {
        const [showLess, setShowLess] = React.useState(true);
      
        if (text.length < length) {
          return <p>{text}</p>;
        }
      
        return (
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: showLess ? `${text.slice(0, length)}...` : text,
              }}
            ></p>
            <a
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => setShowLess(!showLess)}
            >
              &nbsp;View {showLess ? "More" : "Less"}
            </a>
          </div>
        );
    };

    return (
        <>
            <LayoutDefault className="width-1200">
                <img  className='dirt-img' src={Dirt} alt="Dirt" />

                <a style={{ fontSize: '20px', margin: '30px 0', display: 'block' }} href="/">&lt; Back</a>

                <h1 style={{ marginBottom: '23px' }} className="animate__animated animate__fadeInLeft animate__fast">Logistics Requests</h1>
                <p style={{ marginBottom: '78.5px' }} className="animate__animated animate__fadeInLeft animate__fast">Submit basic shipment requests, intercompany transfers, and track internal cross border trips.</p>
                
                <div className="block-row row">
                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={Globe} alt="Globe" />
                        <h2>CA/US Border Crossing</h2>
                        <SmartText text="If someone from K.A. Group is crossing the border in any direction and bringing something with them." />
                        <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-19967/A9PEJV894M4FH1GEYQ?Destination%20List=Logistics" target="_blank" rel="noopener noreferrer">Submit Request</a>
                    </div>

                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={Box} alt="Box" />
                        <h2>Basic Shipment Request</h2>
                        <SmartText text="If you need something moved from K-Tec to anywhere via normal methods like UPS or FedEx." />
                        <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-20647/APVJABAHP0LFD1QURZ?Destination%20List=Logistics" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                    </div>

                    <div className="animate__animated animate__fadeInUp animate__delay-1s animate__fast block column">
                        <img src={Transfer} alt="Transfer" />
                        <h2>Intercompany Transfer</h2>
                        <SmartText text="Shipping items back and forth within the K.A. Group of companies." />
                        <a className="custom-btn" href="https://forms.clickup.com/14214717/f/dhthx-20682/UCYSSQX3NENLVCHIR5?Destination%20List=InterCo" target="_blank" rel="noopener noreferrer">Submit Request</a>                        
                    </div>

                </div>
            </LayoutDefault>
        </>
    );
}

export default Logistics;